import React, { useContext } from 'react'
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import * as Icon from 'react-feather'
// import { useHistory } from 'react-router-dom'
import _ from 'underscore'
import { AuthenticationContext
  // , generateSeamlessAuthCredentials 
} from '@cloudmlm/cloudmlm-auth'
import urlConstant from '../../../constants/urlConstant'
import dataConstant from '../../../constants/dataConstants'
import { httpGet, httpPost } from '../../../services/commonService'
// import { endApiLoader, startApiLoader } from '../../../services/apiLoaderService'
import './Navbar.css'
import { withRouter } from 'react-router-dom';
import { Dialog } from '@progress/kendo-react-dialogs'
import { withTranslation } from 'react-i18next'

let acctId = 0;

const UserDropdown = props => {
  const { logUserOut } = useContext(AuthenticationContext)
  // const history = useHistory()

  // const GoToAffiliate = e => {
  //   return history.push({ pathname: '/affiliate' })
  // }

  return (
    <DropdownMenu right>
      {/* 
      {!props.affiliateRole && (
        <DropdownItem tag="button" onClick={GoToAffiliate}>
          <span className="align-middle">Become an Affiliate</span>
        </DropdownItem>
      )}
      */}
      <DropdownItem onClick={() => {
      logUserOut();
      localStorage.clear(); // Clear local storage on sign out
    }}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Sign Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

// const rankTypeList = dataConstant.RankType
// const affiliateRankDetail = _.find(rankTypeList, function (item) {
//   return item.RankName == dataConstant.RoleType.Affiliate
// })
// const ambassadorRankDetail = _.find(rankTypeList, function (item) {
//   return item.RankName == dataConstant.RoleType.Ambassador
// })

class NavbarUser extends React.PureComponent {
  
  static contextType = AuthenticationContext
  constructor(props) {
    super(props)
    this.getProfilePicDetails = this.getProfilePicDetails.bind(this)
    this.getUserSpecificData = this.getUserSpecificData.bind(this)
    // this.handleShopNow = this.handleShopNow.bind(this)
  }

  state = {
    navbarSearch: false,
    suggestions: [],
    userProfilePicPath: '',
    userName: '',
    isAffiliate: false,
    refID: '',
    isPurchasedPackage: false,
    isConsultantPackagePurchased: false,
    loading: true,
    isNotification: false,
    isRequired: false,
    isPopupOpen: false,
    refURL: '',
    IsBYLDerEnrolled: false
  }

  componentDidMount() {
    // axios.get("/api/main-search/data").then(({ data }) => {
    //	this.setState({ suggestions: data.searchResult })
    // })
    this.getProfilePicDetails()
    this.getUserSpecificData()
  }

  componentDidUpdate() {
    if(this.context.isLoggedIn)
    {
      this.getUserSpecificData()
    }
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    })
  }

  // handleShopNow = () => {
  //   startApiLoader()
  //   generateSeamlessAuthCredentials(this.context, urlConstant.SeamlessLogin.SeamlessLoginCreate)
  //     .then(result => {
  //       window.location = `${process.env.REACT_APP_RETAIL_SHOPPING_SITE_URL}/seamless-auth/${result}`
  //     })
  //     .catch(e => {
  //       console.error(e)
  //       endApiLoader()
  //     })
  // }

  openPopup = () => {
    this.setState((prevState) => ({
      ...prevState,
      isPopupOpen: true
    }));
  };

  closePopup = () => {
    this.setState((prevState) => ({
      ...prevState,
      isPopupOpen: false
    }));
  };

  getProfilePicDetails = () => {
    const getProfilePicDetailsURL = urlConstant.User.GetUserProfilePicDetails
    httpGet(
      getProfilePicDetailsURL,
      true,
      true,
      this.context.accessToken,
      this.context.impersonateEmail,
    )
      .then(response => {
        if (response.Status === dataConstant.APIResponseStatus.Success) {
          const responseData = response.Values
          this.setState({
            userProfilePicPath:
              responseData.StoredPath != null ? responseData.StoredPath : dataConstant.UserImageURL,
            userName: responseData.UserName,
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  getUserSpecificData = async () => {
    const getUserSpecificDetailsURL = `${urlConstant.User.GetUserSpecificDetails}`
    await httpGet(
      getUserSpecificDetailsURL,
      true,
      true,
      this.context.accessToken,
      this.context.impersonateEmail,
    )
      .then(response => {
        if (response !== null && response.Value !== null && response.Value.Status === dataConstant.APIResponseStatus.Success) {
          const responseData = response.Value.Data
          acctId = responseData.AcctID
          const userRoleDeatil =
            responseData.UserRolesdetail != null ? responseData.UserRolesdetail : ''
          const roleName = _.pluck(userRoleDeatil, 'RoleName')
          const affiliateRole = _.contains(roleName, dataConstant.RoleType.Affiliate)
          //const ambassadorRole = _.contains(roleName, dataConstant.RoleType.Ambassador)
          this.setState({
            isAffiliate: affiliateRole,
            refID: responseData.RefID,
            isPurchasedPackage: responseData.IsPackagePurchased,
            isConsultantPackagePurchased: responseData.IsConsultantPackagePurchased,
            loading: false,
            IsBYLDerEnrolled: responseData.IsBYLDerEnrolled
          })
          this.getRefrenceName(responseData.RefID)
        }
      })
      .catch(err => {
        console.error(err)
        this.setState({ loading: false });
      })
  }

  getRefrenceName = async (refID) => {
    const dataModel = {
      RefId: refID
    };
    await httpPost(urlConstant.IBOSignup.GetRefById, dataModel, true)
      .then(response => {
        if (response.Status !== dataConstant.APIResponseStatus.Failure) {
          this.setState(prevState => ({
            ...prevState,
            refURL: response.Value.RefURL
          }));
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  render() {
    const { history } = this.props;
    const { t } = this.props
    return (
      <>
        {!this.state.loading && (
          <>
            {this.context.impersonateEmail && (
              <div className="impersonate-login">
                <i className="fa fa-user-secret mr-50"></i>You are logged in as impersonate user!
              </div>
            )}
            <ul className="nav navbar-nav navbar-nav-user ml-auto">
              <div className="row pr-2">
                {!this.state.isPurchasedPackage && (
                  <button
                    onClick={this.state.IsBYLDerEnrolled ? () => history.push('/membership') :() => this.openPopup()}
                    type="button"
                    className="btn btn-sm btn-primary"
                  >
                    {t('Navbar.Upgrade')}
                  </button>
                )}
              </div>
              <div className="row cm-header-user-info position-relative m-0">
                <div className="col-12 pr-5">
                  <div className="">
                    {/* <span className=""> Elite 10K</span> */}
                    {this.state.userName !== null ? (
                      <span className="">
                        {this.props.userPrefix} {this.state.userName} {' ('+acctId+')'}
                      </span>
                    ) : (
                      <span className="">{ }</span>
                    )}
                  </div>
                  <div className="pr-2">
                    {/* <span className="pr-2">ETQV: 10,000</span>
                  <span className="">CV: 9862</span> */}
                  </div>
                </div>
                <span className="cm-pro-img" data-tour="user">
                  <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
                    <DropdownToggle tag="a" className="nav-link dropdown-user-link px-0">
                      <span data-tour="user">
                        <img
                          src={this.state.userProfilePicPath}
                          className="round"
                          height="40"
                          width="40"
                          alt="avatar"
                        />
                      </span>
                    </DropdownToggle>
                    <UserDropdown
                      {...this.props}
                      affiliateRole={
                        this.state.isAffiliate
                      }
                    />
                  </UncontrolledDropdown>
                </span>
                <button onClick={this.context.logUserOut} hidden>
                {t('Navbar.SignOut')}
                </button>
              </div>
            </ul>
          </>
        )}

        {this.state.isPopupOpen && (
          <Dialog
            className="cm-kr-dialog font-weight-bold px-1 px-md-0"
            title={t('Navbar.SignUp')}
            onClose={() => this.closePopup()}
            minWidth={300}
          >
            <div className="text-left">
              <button
                className="btn btn-primary btn-block"
                disabled={false}
                onClick={() => history.push(`/IBOSignup/BYLDer/${this.state.refURL}`, { upgrade: true })}
              >
                {t('Navbar.BYLDer')}
              </button>
            </div>
            {
              !this.state.isConsultantPackagePurchased && (
                <div className="text-left">
                  <p> </p>
                  <button
                    className="btn btn-primary btn-block"
                    disabled={false}
                    onClick={() => history.push(`/IBOSignup/Consultant/${this.state.refURL}`, { upgrade: true })}
                  >
                    {t('Navbar.Consultant')}
                  </button>
                </div>
              )
            }
          </Dialog>
        )}
      </>
    )
  }
}

NavbarUser.contextType = AuthenticationContext

export default withTranslation()(withRouter(NavbarUser))
import i18n from "../i18n";

const dataConstant = {
  KendoFilter: {
    take: 10,
    skip: 0,
    filterable: true,
    sortable: true,
    pageable: { buttonCount: 4, pageSizes: true },
    resizable: true,
    reorderable: true
  },
  NumericFixedDigit: 2,
  DateFormatArray: [
    "MM/DD/YYYY", // 01. 12/30/2020
    "{0:MM/dd/yyyy}", // 02. 12/30/2020
    "MM/DD/YYYY HH:mm:ss", // 03. 12/30/2020 15:12:20
    "{0:MM/dd/yyyy HH:mm:ss}",
    "{0:d}"
  ],
  MemberShipInvoiceStatusList: [
    i18n.t("Common.Active"),
    i18n.t("Common.InActive"),
    i18n.t("Common.Canceled"),
    i18n.t("Common.Paid"),
    i18n.t("Common.Pending")
  ],
  MemberShipInvoiceStatusCancelled: "Canceled",
  SmartCartStatusDeleted: "Deleted",
  ConfirmationTitle: "Confirmation",
  NoSpecialCharacterRegex: new RegExp(/^[0-9a-zA-Z ]+$/),
  NoAlphabetCharacterRegex: new RegExp(/^[0-9]+$/),
  NoAlphabetCharacterWithOneSpacialCharRegex: new RegExp(/^[0-9-]+$/),
  NoSpecialCharacterWithWhiteSpaceRegex: new RegExp(/^[0-9a-zA-Z]+$/),
  PasswordValidation: new RegExp("((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{4,64})"),
  WhiteSpaceRegex: new RegExp(/\s/),
  EmailRegex: new RegExp(/\S+@\S+\.\S+/),
  NumberAndNull: new RegExp('^(null|\\d+(\\.\\d+)?)$'),
  PhoneFieldNumbersRegex: new RegExp(/^[0-9()\-\s]+$/),
  NoImageURL: require("../assets/img/NoImage.png"),
  PdfImageURL: require("../assets/img/pdf.png"),
  UserImageURL: require("../assets/img/defaultUser1.png"),
  Undefined: undefined,
  AESEncryptDecryptKey: `${process.env.REACT_APP_AES_ENCRYPT_DECRYPT_KEY}`,
  imgExtensions: [".jpg", ".jpeg", ".png"],
  FileSize: 2097152,
  ConfirmationActionButton: {
    Yes: "Common.Yes",
    No: "Common.No",
    Close: "Close"
  },
  ItemSubType: {
    CustomPackage: "Custom Package",
    PredefinedPackage: "Predefined Package",
    AdditionalItem: "Additional Item",
    FeaturedMembership: "Featured Membership",
    CustomMembership: "Custom Membership"
  },
  WarningLogo: require("../assets/img/icon-warning.png"),
  SignupLeadGUID: "SignupLeadGUID",
  APIResponseStatus: {
    Success: "Success",
    Failure: "Failure",
    Warning: "Warning",
    Undefined: "undefined"
  },
  MinMaxLength: {
    CharLimit5: 5,
    CharLimit10: 10,
    CharLimit15: 15,
    CharLimit20: 20,
    CharLimit25: 25,
    CharLimit50: 50,
    CharLimit100: 100,
    CharLimit150: 150,
    CharLimit200: 200,
    CharLimit250: 250,
    CharLimit255: 255,
    CharLimit300: 300,
    CharLimit500: 500,
    CharLimit1000: 10,
    ZipcodeMax: 25,
    ZipcodeMin: 0,
    CVVMax: 4
  },
  ToastNotificationTimeOut: 3000,
  TwoDigitConstValue: 2,
  ZeroValue: 0,
  OneValue: 1,
  AllDropDown: {
    CoutryDropDown: "CoutryDropDown",
    CommissionTypeDropDown: "CommissionTypeDropDown",
    RoleDropDown: "RoleDropDown",
    CurrencyDropDown: "CurrencyDropDown",
    TimeZoneDropDown: "TimeZoneDropDown",
    CustomerTypesDropDown: "CustomerTypesDropDown",
    EmailTemplateTagDropDown: "EmailTemplateTagDropDown",
    ItemAttributeDropDown: "ItemAttributeDropDown",
    ItemCategoryDropDown: "ItemCategoryDropDown",
    ItemDropDown: "ItemDropDown",
    ItemSubTypesDropDown: "ItemSubTypesDropDown",
    ItemListByItemSubTypeDropDown: "ItemListByItemSubTypeDropDown",
    ItemTypesDropDown: "ItemTypesDropDown",
    LanguageDropDown: "LanguageDropDown",
    MediaCategoriesDropDown: "MediaCategoriesDropDown",
    MediaPageDropDown: "MediaPageDropDown",
    PaymentMethodDropDown: "PaymentMethodDropDown",
    QualificationDropDown: "QualificationDropDown",
    RankDropDown: "RankDropDown",
    ShippingMethodDropDown: "ShippingMethodDropDown",
    UserDropDown: "UserDropDown",
    PromoActionDropDown: "PromoActionDropDown",
    PromoEventDropDown: "PromoEventDropDown",
    StateDropDown: "StateDropDown",
    ReinH2OFinancingDropDown: "ReinH2OFinancingDropDown",
    LeadFinancingDropDown: "LeadFinancingDropDown",
    ProductInterestedDropDown: "ProductInterestedDropDown",
    LeadRoofAgeDropDown: "LeadRoofAgeDropDown",
    LeadRoofTypeDropDown:"LeadRoofTypeDropDown",
    LeadMountingPreferenceDropDown: "LeadMountingPreferenceDropDown",
    LeadWorkDropDown:"LeadWorkDropDown",
    LeadStatusDropDown:"LeadStatusDropDown",
    AffiliateCommissionDropDown:"AffiliateCommissionDropDown",
    LeadKindofElectricalPanelDropDown:"LeadKindofElectricalPanelDropDown",
    ReinAffiliateCommissionDropDown:"ReinAffiliateCommissionDropDown",
  },
  EventComponents: {
    BuyingCertainProduct: "BuyingCertainProduct",
    BuyingCertainAmount: "BuyingCertainAmount",
    MinimumRankRequirement: "MinimumRankRequirement",
    Location: "Location"
  },
  CertainAmountType: {
    InvoiceTotal: "invoicetotal",
    SubTotal: "subtotal"
  },
  PromotionType: {
    FeaturedPromotion: "Featured Promotion",
    ApplicablePromotion: "Applicable Promotion"
  },
  ActionComponents: {
    AddProduct: "AddProduct",
    Discount: "Discount",
    FreeShipping: "FreeShipping",
    GiftCard: "GiftCard"
  },
  DiscountLevel: {
    ItemLevel: "ItemLevel",
    OrderLevel: "OrderLevel"
  },
  DiscountApplyOn: {
    AppliedToSubTotal: "AppliedToSubTotal",
    AppliedToOrderTotal: "AppliedToOrderTotal"
  },
  RegistrationType: {
    Personal: "Personal",
    Company: "Company"
  },
  imgExtensionsWithOutDots: ["jpg", "jpeg", "png"],
  PlacementPreference: [
    // {
    //   label: "Holding Tank",
    //   value: "H"
    // },
    {
      label: "Alternate L-R Legs",
      value: "A"
    },
    {
      label: "Place on Left Leg",
      value: "L"
    },
    {
      label: "Place on Right Leg",
      value: "R"
    }
  ],
  OrderStatusList: ["Canceled", "Paid", "Pending", "Refunded"],
  NumberFormat: "{0:c2}",
  MediaType: {
    TextBlog: "TextBlog",
    YouTubeVideo: "YouTube Video",
    VimeoVideo: "Vimeo Video",
    Picture: "Picture",
    PDFFileUpload: "PDF File Upload"
  },
  Pending: "Pending",
  KendoTextBoxFormat: {
    NumericTextBoxFormatN0: "n0",
    NumericTextBoxFormatN2: "n2",
    NumericTextBoxFormatN4: "n4",
    NumericTextBoxFormatC2: "c2",
    NumericTextBoxFormatP: "p"
  },
  InlineShareButtonsConfig: {
    alignment: "left", // alignment of buttons (left, center, right)
    color: "social", // set the color of buttons (social, white)
    enabled: true, // show/hide buttons (true, false)
    font_size: 16, // font size for the buttons
    labels: null, // button labels (cta, counts, null)
    language: "en", // which language to use (see LANGUAGES)
    networks: [
      // which networks to include (see SHARING NETWORKS)
      "email",
      "linkedin",
      "messenger",
      "facebook",
      "twitter",
      "whatsapp"
    ],
    // "blogger"| "delicious" | "digg" | "email"  | "facebook" | "flipboard"| "google"| "linkedin"| "livejournal"| "mailru"| "meneame"| "messenger"| "oknoklassniki"| "pinterest"| "print"| "reddit"| "sharethis"| "sms"| "stumbleupon"| "tumblr"| "twitter"| "wechat"| "weibo"| "whatsapp"| "xing";
    padding: 10, // padding within buttons (INTEGER)
    radius: 4, // the corner radius on each button (INTEGER)
    show_total: false,
    size: 30, // the size of each button (INTEGER)
    //  // OPTIONAL PARAMETERS
    // url: 'https://www.sharethis.com', // (defaults to current url)
    // image: 'https://bioreigns.com/assets/img/home/2Curcumin_Hover_MDv3.png',  // (defaults to og:image or twitter:image)
    // description: 'custom text',       // (defaults to og:description or twitter:description)
    title: "Smart Cart", // (defaults to og:title or twitter:title)
    message: "Smart Cart", // (only for email sharing)
    subject: "Smart Cart" // (only for email sharing)
    // username: 'Smart Cart' // (only for twitter sharing)
  },
  promotionAppliedOn: {
    retail: "Retail",
    ambassador: "Consultant",
    both: "Both"
  },
  AffiliateTermsAndConditions: {
    DocumentName: "Affiliate Terms And Conditions",
    DocumentType: "TermsAndCondition"
  },
  RankType: [
    {
      DisplayRankID: 1,
      RankName: "RetailCustomer"
    },
    {
      DisplayRankID: 2,
      RankName: "Affiliate"
    },
    {
      DisplayRankID: 3,
      RankName: "Consultant"
    }
  ],
  RoleType: {
    RetailCustomer: "RetailCustomer",
    Affiliate: "Affiliate",
    Ambassador: "Consultant"
  },
  MediaConfigurationType: {
    AffiliateVideo: "Affiliate Video",
    AmbassadorVideo: "Consultant Video"
  },
  promotionCode: {
    affiliate: "Affiliate"
  },
  promoActionType: {
    addProduct: "AddProduct",
    discount: "Discount",
    freeShipping: "FreeShipping",
    giftCard: "GiftCard"
  },
  repPoliciesAndProcedureDocumentDet: {
    DocumentName: "Rep Policies And Procedures",
    DocumentType: "Policies"
  },
  termsAndConditionPreviousPage: {
    becomeAnAffiliate: "becomeAnAffiliate",
    enrollmentForm: "enrollmentForm"
  },
  MemberShipInvoiceStatus: {
    Active: "Active",
    Deleted: "Deleted"
  },
  BackOfficeInvoice: "BackOffice",
  loginLogo: require("../assets/img/logo/logo-bo-login.png"),
  LeadCollections: {
    AddFormTitle: 'Add',
    EditFormTitle: 'Edit',
    allowedExtensions: ["jpeg", "jpg", "png", "pdf"],
    LeadStatus: {
      New: "New",
      Closed: "Closed",
      Deleted: "Deleted",
      ApprovalPendingFromMentor: "Approval pending from Mentor",
      ApprovedByMentor: "Approved by Mentor",
      RejectedByMentor: "Rejected by Mentor"
    },
    FreedomPortalLogin: 'https://portal.freedomforever.com/login',
    BecomeMentor: {
      OthersMentor: 5,
      SelfMentor: 3
    },
    MAX_LENGTH: 10,
    MAX_FILE_SIZE_MB: 10
  },
  CountryList: ["us", "ca", "mx", "ph", "gb", "za", "do"],
  PrimaryPaymentMethods: [
    {
      value: 5,
      label: "Credit Card"
    },
    {
      value: 7,
      label: "iWallet"
    },
  ],
  SecondaryPaymentMethods: [
    {
      value: 5,
      label: "Credit Card"
    },
    {
      value: 7,
      label: "iWallet"
    },
    {
      value: 8,
      label: "None"
    },
  ],
  ReinH2O:
  {
    label: "ReinH2O",
    value: 1
  },
  Solar:
  {
    label: "Solar",
    value: 2
  }
};

export default dataConstant;
